import React from "react";
import { CloseIcon } from "../Icons";

interface MessageProps {
  text: string;
  onClose: () => void;
}

const ComparationMessage = (props: MessageProps) => {
  return (
    <div className="fixed inset-0 bg-cblue bg-opacity-60 transition-opacity ">
      <div className="flex flex-col h-screen justify-center items-center">
        <div className="flex flex-col w-fill bg-cblue text-white justify-center items-center pb-6 pt-2 px-4 text-justify rounded-lg">
          <div className="self-end">
            <button
              onClick={props.onClose}
              className="text-white hover:border self-end hover:border-white rounded w-[26px] h-[26px] mb-1 text-center"
            >
              <CloseIcon />
            </button>
          </div>
          <div>
            <p className="whitespace-pre">{props.text}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComparationMessage;
