import React from "react";
import RegionButton from "../components/buttons/RegionButton";

const RegionSelector = () => {
  const background = "/assets/portada.jpg";
  return (
    <div className="bg-[#0E1937] flex flex-col h-full w-full justify-center items-center">
      <div
        className="h-full w-full justify-center"
        style={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="absolute self-end top-2 left-[85%]">
          <div>
            <img src="/assets/logo.png" width={200} height={200} />
          </div>
        </div>
        <div className="flex flex-col w-full mt-4 items-center">
          <div className=" w-[60%] self-center bg-cpink bg-opacity-80 rounded-md text-center font-semibold text-white py-1">
            <h1>Select your region</h1>
          </div>
        </div>
        <div className="flex flex-col w-full mt-20 items-center">
          <RegionButton text="North America" />
          <RegionButton text="Central and Eastern Europe" />
          <RegionButton text="South Asia" />
          <RegionButton text="Southeast Asia" />
          <RegionButton text="Latin America" />
        </div>
        <div className="flex flex-row mt-16 ml-10 items-center">
          <div className=" font-semibold text-5xl text-white py-1">
            <h1>Price your team!</h1>
          </div>
          <div className="ml-4 self-end font-semibold text-white">
            <h1>
              How much can your team cost across different regions of the world?
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegionSelector;
