import React from "react";
import { CloseIcon } from "../Icons";

interface MessageProps {
  text: string;
  onClose: () => void;
}

const CommonMessage = (props: MessageProps) => {
  return (
    <div className="fixed inset-0 bg-cblue bg-opacity-60 transition-opacity ">
      <div className="flex flex-col h-screen justify-center items-center">
        <div className="flex flex-col w-1/3">
          <button
            onClick={props.onClose}
            className="text-white hover:border self-end hover:border-white rounded w-[26px] h-[26px] mb-1 text-center"
          >
            <CloseIcon />
          </button>
        </div>
        <div className="flex  w-1/3 bg-white justify-center items-center py-6 rounded-lg">
          <div>
            <h1>{`` + props.text}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonMessage;
