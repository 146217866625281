import React from "react";
import { useLocation } from "react-router-dom";
//My components
import ComparationMessage from "../components/modals/ComparationModal";

const TotalPrice = () => {
  const location = useLocation();
  const { rowState, regionState } = location.state;
  const [message, setMessage] = React.useState("");
  const [isActive, setIsActive] = React.useState(false);
  const [lines, setLines]: any = React.useState([]);
  const [isHover, setIsHover] = React.useState(false);
  let m1 = 0;
  let m2 = 0;
  let m3 = 0;
  let m4 = 0;
  let m5 = 0;
  let m6 = 0;
  let m7 = 0;
  let m8 = 0;
  let m9 = 0;
  let m10 = 0;
  let m11 = 0;
  let m12 = 0;
  let totLA = 0;
  let totCE = 0;
  let totAS = 0;
  let totSA = 0;
  let totNA = 0;
  let totNS = 0;

  const thList: any = [];
  const durantion = rowState.map((obj: any) => {
    const res = obj.engagementRow.split(" ");
    return res[0];
  });

  const sortArray = durantion.sort((a: number, b: number) => b - a);

  for (let i = 1; i <= sortArray[0]; i++) {
    thList.push(
      <th key={i} className="text-white py-1  font-semibold text-center">
        {"M" + i}
      </th>
    );
  }

  React.useEffect(() => {
    GetTextLines();
  }, []);

  const getListPrices = (lengthEngagement: string, profile: string) => {
    const resl = lengthEngagement.split(" ");
    const currentLenght: any = resl[0];
    const tdList: any = [];
    let priceNearshore = "0";
    if (lines.length > 0) {
      const tempArray = [...lines];
      tempArray.forEach((obj) => {
        const arrayLine = obj.split("|");
        if (arrayLine[0] === profile) {
          priceNearshore = arrayLine[6];
        }
      });
    }
    const priceInNumber = priceNearshore.replace(/[$]/g, "");
    const priceperMonth = parseFloat(priceInNumber) * 160;

    for (let i = 1; i <= currentLenght; i++) {
      switch (i) {
        case 1:
          m1 += priceperMonth;
          break;
        case 2:
          m2 += priceperMonth;
          break;
        case 3:
          m3 += priceperMonth;
          break;
        case 4:
          m4 += priceperMonth;
          break;
        case 5:
          m5 += priceperMonth;
          break;
        case 6:
          m6 += priceperMonth;
          break;
        case 7:
          m7 += priceperMonth;
          break;
        case 8:
          m8 += priceperMonth;
          break;
        case 9:
          m9 += priceperMonth;
          break;
        case 10:
          m10 += priceperMonth;
          break;
        case 11:
          m11 += priceperMonth;
          break;
        case 12:
          m12 += priceperMonth;
          break;
      }

      tdList.push(
        <td key={i} className="py-2 text-center">
          {priceperMonth.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
      );
    }

    return tdList;
  };

  const getRegionTotal = (
    lengthEngagement: string,
    profile: string,
    quantity: number
  ) => {
    const resl = lengthEngagement.split(" ");
    const currentLenght: any = resl[0];

    let priceNearshore = "0";
    let priceLatinAmerica = "0";
    let priceCentralEurope = "0";
    let priceAsia = "0";
    let priceSouthAsia = "0";
    let priceNorthAmerica = "0";

    if (lines.length > 0) {
      const tempArray = [...lines];
      tempArray.forEach((obj) => {
        const arrayLine = obj.split("|");
        if (arrayLine[0] === profile) {
          priceLatinAmerica = arrayLine[1];
          priceCentralEurope = arrayLine[2];
          priceAsia = arrayLine[3];
          priceSouthAsia = arrayLine[4];
          priceNorthAmerica = arrayLine[5];
          priceNearshore = arrayLine[6];
        }
      });
    }
    const priceInNumber = priceNearshore.replace(/[$]/g, "");
    const priceLAInNumber = priceLatinAmerica.replace(/[$]/g, "");
    const priceCEInNumber = priceCentralEurope.replace(/[$]/g, "");
    const priceASInNumber = priceAsia.replace(/[$]/g, "");
    const priceSAInNumber = priceSouthAsia.replace(/[$]/g, "");
    const priceNAInNumber = priceNorthAmerica.replace(/[$]/g, "");

    totLA += currentLenght * parseFloat(priceLAInNumber) * quantity * 160;
    totCE += currentLenght * parseFloat(priceCEInNumber) * quantity * 160;
    totAS += currentLenght * parseFloat(priceASInNumber) * quantity * 160;
    totSA += currentLenght * parseFloat(priceSAInNumber) * quantity * 160;
    totNA += currentLenght * parseFloat(priceNAInNumber) * quantity * 160;
    totNS += currentLenght * parseFloat(priceInNumber) * quantity * 160;
  };

  const getTotalRow = (lengthEngagement: string, profile: string) => {
    const resl = lengthEngagement.split(" ");
    const currentLenght: any = resl[0];

    let priceNearshore = "0";
    if (lines.length > 0) {
      const tempArray = [...lines];
      tempArray.forEach((obj) => {
        const arrayLine = obj.split("|");
        if (arrayLine[0] === profile) {
          priceNearshore = arrayLine[6];
        }
      });
    }
    const priceInNumber = priceNearshore.replace(/[$]/g, "");

    let total = 0;
    for (let i = 1; i <= currentLenght; i++) {
      total += parseFloat(priceInNumber) * 160;
    }
    return total.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  const getTotalCol = (lengthEngagement: string) => {
    const resl = lengthEngagement.split(" ");
    const currentLenght: any = resl[0];
    const tdList: any = [];

    for (let i = 1; i <= currentLenght; i++) {
      switch (i) {
        case 1:
          tdList.push(
            <td key={i} className="py-2 text-center">
              {m1.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </td>
          );
          break;
        case 2:
          tdList.push(
            <td key={i} className="py-2 text-center">
              {m2.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </td>
          );
          break;
        case 3:
          tdList.push(
            <td key={i} className="py-2 text-center">
              {m3.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </td>
          );
          break;
        case 4:
          tdList.push(
            <td key={i} className="py-2 text-center">
              {m4.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </td>
          );
          break;
        case 5:
          tdList.push(
            <td key={i} className="py-2 text-center">
              {m5.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </td>
          );
          break;
        case 6:
          tdList.push(
            <td key={i} className="py-2 text-center">
              {m6.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </td>
          );
          break;
        case 7:
          tdList.push(
            <td key={i} className="py-2 text-center">
              {m7.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </td>
          );
          break;
        case 8:
          tdList.push(
            <td key={i} className="py-2 text-center">
              {m8.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </td>
          );
          break;
        case 9:
          tdList.push(
            <td key={i} className="py-2 text-center">
              {m9.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </td>
          );
          break;
        case 10:
          tdList.push(
            <td key={i} className="py-2 text-center">
              {m10.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </td>
          );
          break;
        case 11:
          tdList.push(
            <td key={i} className="py-2 text-center">
              {m11.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </td>
          );
          break;
        case 12:
          tdList.push(
            <td key={i} className="py-2 text-center">
              {m12.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </td>
          );
          break;
      }
    }
    return tdList;
  };

  const viewMessage = async (id: string) => {
    const urlMessage = "TextFiles/Messages.txt";
    await fetch(urlMessage)
      .then((response) => response.text())
      .then((text) => {
        const arrayText = text.split("|");
        switch (id) {
          case "LA":
            setMessage(arrayText[0]);
            break;
          case "CE":
            setMessage(arrayText[1]);
            break;
          case "AS":
            setMessage(arrayText[2]);
            break;
          case "SA":
            setMessage(arrayText[3]);
            break;
          case "NA":
            setMessage(arrayText[4]);
            break;
        }
        setIsActive(!isActive);
      });
  };

  const GetTextLines = async () => {
    await fetch("TextFiles/Prices.txt")
      .then((response) => response.text())
      .then((text) => {
        const linenArray = text.split("\n");
        setLines(linenArray);
      });
  };

  return (
    <div className="w-full h-full flex flex-col px-10 bg-white ">
      {isActive && (
        <ComparationMessage
          text={message}
          onClose={() => setIsActive(!isActive)}
        />
      )}
      <div className="w-full bg-cpink rounded-md text-center mt-2 font-semibold text-white py-1">
        <h1>Your team with Nearshore Providers</h1>
      </div>

      <div className="overflow-x-auto max-h-[48%] rounded-lg w-full">
        <table className="table-auto  bg-cgray rounded-lg w-full  overflow-hidden">
          <thead className="bg-cblue">
            <tr>
              <th className="text-white py-1  font-semibold text-center">
                {"Profile"}
              </th>
              {thList}
              <th className="text-white py-1  font-semibold text-center">
                {"Total"}
              </th>
            </tr>
          </thead>
          <tbody className="">
            {rowState.length > 0 &&
              rowState.map((obj: any) => {
                getRegionTotal(
                  obj.engagementRow,
                  obj.profileRow,
                  obj.quantityRow
                );
                const rowList: any = [];
                for (let i = 0; i < obj.quantityRow; i++) {
                  rowList.push(
                    <tr key={i}>
                      <td className=" py-2 text-center">{obj.profileRow}</td>
                      {getListPrices(obj.engagementRow, obj.profileRow)}
                      {sortArray[0] - obj.engagementRow.split(" ")[0] >= 1 && (
                        <td
                          className="text-end"
                          colSpan={
                            sortArray[0] - obj.engagementRow.split(" ")[0]
                          }
                        ></td>
                      )}
                      <td className="text-center">
                        {getTotalRow(obj.engagementRow, obj.profileRow)}
                      </td>
                    </tr>
                  );
                }
                return rowList;
              })}
            <tr className="bg-caqua text-white font-semibold">
              <td className=" py-2  text-center">{"Total"}</td>
              {getTotalCol(sortArray[0] + " m")}
              <td className="bg-orange-500 py-1  font-semibold text-center">
                {totNS.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="w-full bg-cpink rounded-md text-center font-semibold mt-4 text-white py-1">
        <h1>How much would your team cost in other regions of the world?</h1>
      </div>
      <div className="overflow-x-auto h-[19%] rounded-lg w-full">
        <table className="table-auto  bg-cgray rounded-lg w-full  overflow-hidden">
          <thead className="bg-cblue">
            <tr>
              <th
                onClick={() => viewMessage("LA")}
                className="text-white py-1 border-b border-b-white font-semibold text-center hover:cursor-pointer hover:bg-blue-700 "
              >
                {"Latin America"}
              </th>
              <th
                onClick={() => viewMessage("CE")}
                className="text-white py-1 border-b border-b-white font-semibold text-center hover:cursor-pointer hover:bg-blue-700"
              >
                {"Central & Eastern Europe"}
              </th>
              <th
                onClick={() => viewMessage("AS")}
                className="text-white py-1 border-b border-b-white font-semibold text-center hover:cursor-pointer hover:bg-blue-700"
              >
                {"South Asia"}
              </th>
              <th
                onClick={() => viewMessage("SA")}
                className="text-white py-1 border-b border-b-white font-semibold text-center hover:cursor-pointer hover:bg-blue-700"
              >
                {"Southeast Asia"}
              </th>
              <th
                onClick={() => viewMessage("NA")}
                className="text-white py-1 border-b border-b-white font-semibold text-center hover:cursor-pointer hover:bg-blue-700"
              >
                {"North America"}
              </th>
              <th className="text-white py-1 border-b border-b-white font-semibold text-center ">
                {"Nearshore Providers"}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="py-2 text-center">
                {totLA.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </td>
              <td className="py-2 text-center">
                {totCE.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </td>
              <td className="py-2 text-center">
                {totAS.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </td>
              <td className="py-2 text-center">
                {totSA.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </td>
              <td className="py-2 text-center">
                {totNA.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </td>
              <td className="py-2 text-center text-white font-semibold bg-orange-500">
                {totNS.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </td>
            </tr>
            <tr className="bg-caqua text-white font-semibold">
              <td colSpan={6} className="text-center py-1">
                <h1>Infrastucture cost starting at 600 USD per year.</h1>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <a
        href="https://calendly.com/malu-chavez/30min"
        target="_blank"
        className=" w-fit self-center mt-2 rounded-2xl text-[#EB1979] font-semibold text-center text-4xl hover:cursor-pointer hover:text-cpink"
      >
        Let's find the best fit for you!
      </a>

      {isHover && (
        <div className="absolute top-[77%] w-[70%] self-end rounded-lg bg-[#99B7D1] text-sm  text-justify p-3">
          <h1>
            Fees shown in this comparative table are for informational purposes
            only and are not intended, in any manner, to be used as a final
            quote. Prices do not include any expenses or allowances that may be
            incurred by engaging in a specific project or team augmentation.
            Fees may vary based on the type of engagement and technology or a
            particular demand and market value. To provide you with an accurate
            quote, please contact us.
          </h1>
        </div>
      )}

      <div className="absolute top-[90%] mt-2 text-sm">
        <h1
          className="text-justify mr-2 hover:cursor-pointer w-fit"
          onMouseEnter={() => setIsHover(!isHover)}
          onMouseLeave={() => setIsHover(!isHover)}
        >
          * Fees shown in this comparative table are for informational purposes
          only terms and conditions apply.
        </h1>
      </div>
    </div>
  );
};

export default TotalPrice;
