import React from "react";
import { useLocation, NavLink } from "react-router-dom";
// My components
import PreviewPrice from "../components/tables/PreviewPrice";
import CommonMessage from "../components/modals/CommonMessage";

const PriceTable = () => {
  const initialValues = {
    quantity: "",
    profile: "",
    engagement: "",
  };
  const location = useLocation();
  const { regionState } = location.state;
  const [form, setForm] = React.useState(initialValues);
  const [rows, setRows]: any = React.useState([]);
  const [isFields, setIsFields] = React.useState(true);
  const [isRows, setIsRows] = React.useState(true);
  const profileSelector = [
    { profile: "" },
    { profile: "Architect" },
    { profile: "Lead Developer" },
    { profile: "Senior Developer" },
    { profile: "Intermediate Developer" },
    { profile: "Junior Developer" },
    { profile: "Business Analyst" },
    { profile: "DevOps" },
    { profile: "Graphic Designer" },
    { profile: "Project Manager" },
    { profile: "Scrum Master" },
    { profile: "Junior QA (Manual)" },
    { profile: "Intermediate QA (Manual)" },
    { profile: "Senior QA (Manual)" },
    { profile: "Junior QA (Automated)" },
    { profile: "Intermediate QA (Automated)" },
    { profile: "Senior QA (Automated)" },
  ];

  const engagementSelector = [
    { engagementLength: "" },
    { engagementLength: "3 months" },
    { engagementLength: "4 months" },
    { engagementLength: "5 months" },
    { engagementLength: "6 months" },
    { engagementLength: "7 months" },
    { engagementLength: "8 months" },
    { engagementLength: "9 months" },
    { engagementLength: "10 months" },
    { engagementLength: "11 months" },
    { engagementLength: "12 months" },
  ];

  const onAdd = () => {
    if (form.quantity !== "" && form.profile !== "" && form.engagement !== "") {
      const data: any = {
        quantityRow: form.quantity,
        profileRow: form.profile,
        engagementRow: form.engagement,
      };
      setRows([...rows, data]);
      setForm({ ...form, quantity: "", profile: "", engagement: "" });
    } else {
      setIsFields(!isFields);
      console.log("ingresa la información en los 3 campos");
    }
  };

  const onDelete = (id: number) => {
    rows.filter((obj: any, index: number) => {
      return index !== id;
    });

    setRows((prevState: any) =>
      prevState.filter((obj: any, index: number) => {
        return index !== id;
      })
    );
  };

  const onGo = () => {
    if (rows.length <= 0) {
      setIsRows(!isRows);
    }
  };

  return (
    <div className="p-10 w-full">
      {!isFields && (
        <CommonMessage
          text="Please fill all fields"
          onClose={() => setIsFields(!isFields)}
        />
      )}
      {!isRows && (
        <CommonMessage
          text="Add at least one row"
          onClose={() => setIsRows(!isRows)}
        />
      )}
      <div className="w-[25%] py-1 w-fill text-white text-center bg-caqua rounded-md">
        <h1>{regionState}</h1>
      </div>
      <div className="mt-5 flex flex-row">
        <div className="w-[30%] text-center">
          <h1 className="ml-[-20px]">Quantity</h1>
        </div>
        <div className="w-[30%] text-center">
          <h1>Profile</h1>
        </div>
        <div className="w-[30%] text-center">
          <h1>Length of Engagement</h1>
        </div>
      </div>
      <div className="mt-2 flex flex-row">
        <div className="w-[31%]">
          <input
            value={form.quantity}
            onChange={(event) =>
              setForm({ ...form, quantity: event.target.value })
            }
            type="text"
            className="bg-cgray rounded-md w-[90%] py-1 px-2"
          />
        </div>
        <div className="w-[31%]">
          <select
            value={form.profile}
            onChange={(event) =>
              setForm({ ...form, profile: event.target.value })
            }
            className="bg-cgray rounded-md w-[90%] p-1"
          >
            {profileSelector.map((obj, index) => {
              return <option key={index}>{obj.profile}</option>;
            })}
          </select>
        </div>
        <div className="w-[31%]">
          <select
            value={form.engagement}
            onChange={(event) =>
              setForm({ ...form, engagement: event.target.value })
            }
            className="bg-cgray rounded-md w-[90%] p-1"
          >
            {engagementSelector.map((obj, index) => {
              return <option key={index}>{obj.engagementLength}</option>;
            })}
          </select>
        </div>
        <div className="">
          <button
            onClick={onAdd}
            className="bg-cpink rounded-md text-white text-center py-1 px-6 ml-2 hover:bg-pink-300"
          >
            Add
          </button>
        </div>
      </div>
      <PreviewPrice rows={rows} onDelete={onDelete} />
      <div className="mt-4 flex flex-row justify-end items-center">
        {rows.length > 0 ? (
          <NavLink
            to="/totalPrice"
            state={{ rowState: rows, regionState: regionState }}
            className="bg-cpink rounded-md text-white justify-center py-1 px-7 hover:bg-pink-300"
          >
            <h1>Go</h1>
          </NavLink>
        ) : (
          <button
            onClick={onGo}
            className="bg-cpink rounded-md text-white justify-center py-1 px-7 hover:bg-pink-300"
          >
            <h1>Go</h1>
          </button>
        )}
      </div>
    </div>
  );
};

export default PriceTable;
