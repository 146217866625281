import React from "react";
import { NavLink } from "react-router-dom";

interface buttonProps {
  text: string;
}

const RegionButton = (props: buttonProps) => {
  return (
    <div className=" h-10 flex text-center py-1 px-4 my-4 bg-cblue bg-opacity-80 w-[50%] rounded-3xl hover:border border-blue-700 hover:bg-blue-400">
      <NavLink
        to="/priceTable"
        state={{ regionState: props.text }}
        className="w-full"
      >
        <h1 className="text-white text-base py-1">{props.text}</h1>
      </NavLink>
    </div>
  );
};

export default RegionButton;
