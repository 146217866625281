import { Routes, Route } from "react-router-dom";
//Screens
import RegionSelector from "../screens/RegionSelector";
import PriceTable from "../screens/PriceTable";
import TotalPrice from "../screens/TotalPrice";

const AppNavigator = () => {
  return (
    <div className="h-screen w-screen overflow-y-hidden overflow-x-hidden bg-slate-50">
      <div className="flex h-full">
        <Routes>
          <Route path="/" element={<RegionSelector />} />
          <Route path="/priceTable" element={<PriceTable />} />
          <Route path="/totalPrice" element={<TotalPrice />} />
        </Routes>
      </div>
    </div>
  );
};

export default AppNavigator;
