import React from "react";
import { DeleteIcon } from "../Icons";

interface tableProps {
  rows: any;
  onDelete: (id: number) => void;
}

const PreviewPrice = (props: tableProps) => {
  const [lines, setLines]: any = React.useState([]);
  const headers = [
    { name: "Quantity" },
    { name: "Duration" },
    { name: "Profile" },
    { name: "Latin America" },
    { name: "Central & Eastern Europe" },
    { name: "South Asia" },
    { name: "Southeast Asia" },
    { name: "North America" },
    { name: "Nearshore Providers" },
    { name: "" },
  ];

  React.useEffect(() => {
    GetTextLines();
  }, []);

  const GetTextLines = async () => {
    await fetch("TextFiles/Prices.txt")
      .then((response) => response.text())
      .then((text) => {
        const linenArray = text.split("\n");
        setLines(linenArray);
      });
  };

  const getListPrices = (profile: string) => {
    let priceNearshore = "0";
    let priceLatinAmerica = "0";
    let priceCentralEurope = "0";
    let priceAsia = "0";
    let priceSouthAsia = "0";
    let priceNorthAmerica = "0";

    if (lines.length > 0) {
      const tempArray = [...lines];
      tempArray.forEach((obj) => {
        const arrayLine = obj.split("|");
        if (arrayLine[0] === profile) {
          priceLatinAmerica = arrayLine[1];
          priceCentralEurope = arrayLine[2];
          priceAsia = arrayLine[3];
          priceSouthAsia = arrayLine[4];
          priceNorthAmerica = arrayLine[5];
          priceNearshore = arrayLine[6];
        }
      });
    }

    const priceInNumber = priceNearshore.replace(/[$]/g, "");
    const priceLAInNumber = priceLatinAmerica.replace(/[$]/g, "");
    const priceCEInNumber = priceCentralEurope.replace(/[$]/g, "");
    const priceASInNumber = priceAsia.replace(/[$]/g, "");
    const priceSAInNumber = priceSouthAsia.replace(/[$]/g, "");
    const priceNAInNumber = priceNorthAmerica.replace(/[$]/g, "");

    return (
      <>
        <td className="py-2 border-b border-b-black text-center">
          {parseFloat(priceLAInNumber).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td className="py-2 border-b border-b-black text-center">
          {parseFloat(priceCEInNumber).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td className="py-2 border-b border-b-black text-center">
          {parseFloat(priceASInNumber).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td className="py-2 border-b border-b-black text-center">
          {parseFloat(priceSAInNumber).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td className="py-2 border-b border-b-black text-center">
          {parseFloat(priceNAInNumber).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td className="py-2 border-b border-b-black text-center">
          {parseFloat(priceInNumber).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
      </>
    );
  };

  return (
    <div className="overflow-x-auto h-[60%] mt-8 rounded-2xl w-full">
      <table className="table-auto bg-cgray rounded-2xl w-full  overflow-hidden ">
        <thead className="bg-cpink rounded-2xl">
          <tr>
            {headers.map((obj, index) => {
              return (
                <td
                  key={index}
                  className="text-white py-1 font-semibold text-center"
                >
                  {obj.name}
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody className="py-1">
          {props.rows.length > 0 &&
            props.rows.map((obj: any, index: number) => {
              return (
                <tr key={index}>
                  <td className=" py-2 border-b border-b-black text-center">
                    {obj.quantityRow}
                  </td>
                  <td className=" py-2 border-b border-b-black text-center">
                    {obj.engagementRow}
                  </td>
                  <td className=" py-2 border-b border-b-black text-center">
                    {obj.profileRow}
                  </td>
                  {getListPrices(obj.profileRow)}
                  <td className=" py-2 border-b border-b-black text-center">
                    <button
                      onClick={() => props.onDelete(index)}
                      className="text-black self-end hover:text-red-600 rounded w-[26px] h-[26px] mb-1 mr-1 text-center"
                    >
                      <DeleteIcon />
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default PreviewPrice;
